<template>
  <ValidationObserver v-slot="{ passes }">
    <v-form @submit.prevent="passes(answerUpdate)">
      <v-card>
        <v-card-title>
          Answer update
        </v-card-title>
        <v-card-text>
          <ValidationProvider
            v-slot="{ errors }"
            name="quiz"
            rules="required"
          >
            <v-autocomplete
              v-model="answer.quiz_id"
              :items="quizes"
              item-value="id"
              item-text="title"
              label="Quiz"
              :error-messages="errors"
              @change="getQuestionByID(answer.quiz_id)"
            />
          </ValidationProvider>
          <ValidationProvider
            v-if="answer.quiz_id"
            v-slot="{ errors }"
            name="quiz"
            rules="required"
          >
            <v-autocomplete
              v-model="answer.question_id"
              :items="questions"
              item-value="id"
              item-text="title"
              label="Questions"
              :error-messages="errors"
            >
              <template
                #selection="{ item }"
              >
                {{ $strippedContent(item.title) }}
              </template>
              <template
                #item="{ item }"
              >
                {{ $strippedContent(item.title) }}
              </template>
            </v-autocomplete>
          </ValidationProvider>
          <template v-if="answer.question_id">
            <ValidationProvider
              v-slot="{ errors }"
              name="title"
              rules="required"
            >
              <v-text-field
                v-model="answer.title"
                :error-messages="errors"
                label="Title"
                type="text"
                required
              />
            </ValidationProvider>
            <v-icon left>
              mdi-list-status
            </v-icon>
            <BtnToggle
              v-model="answer.is_correct"
              :positive="{text: 'Correct'}"
              :negative="{text: 'Wrong'}"
            />
            <h3 class="mt-4">
              Status
            </h3>
            <v-icon left>
              mdi-check-circle-outline
            </v-icon>
            <BtnToggle
              v-model="answer.status"
              :positive="{text: 'Active'}"
              :negative="{text: 'Inactive'}"
            />
          </template>
        </v-card-text>
        <v-card-actions class="pb-3">
          <v-spacer />
          <v-btn
            type="submit"
            color="primary"
            :loading="loading"
          >
            {{ $t('action.update') }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </ValidationObserver>
</template>

<script>
import BtnToggle from '@/components/BtnToggle'
import authHeader from '@/utils'

export default {
  name: 'AnswerUpdate',
  components: { BtnToggle },
  breadcrumb () {
    return {
      label: this.$t('action.edit') + ' ' + this.answer.title
    }
  },
  data () {
    return {
      loading: false,
      answer: {
        quiz_id: null,
        question_id: null,
        is_correct: false,
        status: true
      },
      questions: [],
      quizes: []
    }
  },
  beforeMount () {
    this.getQuizes()
    this.getAnswerByID()
  },
  methods: {
    getAnswerByID () {
      this.$http
        .get('/answers/?id[]=' + this.$route.params.id, { headers: authHeader() })
        .catch(error => this.displayError(error))
        .then(({ data: { data } }) => {
          if (!data[0]) {
            this.$router.push('/404')
          } else {
            this.answer = data[0]
            this.answer.quiz_id = data[0].question.quiz.id
            this.answer.question_id = data[0].question.id
            this.getQuestionByID(this.answer.quiz_id)
          }
        })
    },
    getQuestionByID (QuizID) {
      this.$http
        .get('/questions/?take=5000&quiz_id=' + QuizID, { headers: authHeader() })
        .catch(error => {
          this.displayError(error)
        })
        .then(({ data }) => {
          this.questions = data.data
        })
    },
    answerUpdate () {
      this.loading = true
      this.$http
        .put('/answers/' + this.$route.params.id, this.answer, { headers: { ...authHeader(), ...{ 'Content-Type': 'multipart/form-data' } } })
        .then(() => {
          this.$store.commit('setNotification', { color: 'success', message: this.$t('action.update_success') })
          this.$router.push({ name: 'dashboard.answer' })
        })
        .catch(error => {
          this.loading = false
          this.displayError(error)
        })
    },
    getQuizes () {
      this.$http
        .get('/quizes/?take=500', { headers: authHeader() })
        .catch(error => {
          this.displayError(error)
        })
        .then(({ data: { data } }) => {
          this.quizes = data
        })
    }
  }
}
</script>
